/**
 * Checks for DY feature flag from init
 * loads DY scripts
 * sets DY recommendationContext
 */
'use client';
import Conditional from '@next-app/components/Conditional';
import Script from 'next/script';
import UpdateDYConsentScript from './UpdateDYConsentScript';

const LoadDynamicYield = ({ init }: any) => {
  const { dynamicYieldInfo } = init?.initResponse || {};
  const { staticScript, dynamicScript, dynamicYieldEnabled } = dynamicYieldInfo;

  return (
    <Conditional if={dynamicYieldEnabled}>
      <>
        <Script
          id={'dy_consent_update'}
          type="text/javascript"
          strategy="beforeInteractive"
        >
          {`
            window.dySetConsent = () => {
              let acceptedConsent = localStorage.getItem('userActiveConsent') == 'false' ? false : true;
              window.DY = window.DY || {};
              window.DY.userActiveConsent = { accepted: acceptedConsent };
            }
            window.dySetConsent()
          `}
        </Script>
        <Script
          id={'dy_context'}
          type="text/javascript"
          strategy="beforeInteractive"
        >
          {`
              window.dyInit = (rc) => {
                window.DY = window.DY || {};
                window.DY.recommendationContext = rc;
            };
          `}
        </Script>
        <Script
          src={dynamicScript}
          type="text/javascript"
          strategy="beforeInteractive"
        />
        <Script
          src={staticScript}
          type="text/javascript"
          strategy="beforeInteractive"
        />
        <UpdateDYConsentScript />
      </>
    </Conditional>
  );
};

export default LoadDynamicYield;
